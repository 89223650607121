import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Group,
  Image,
  LoadingOverlay,
  Menu,
  NumberInput,
  Stack,
  Switch,
  Text,
  rem,
  useMantineTheme,
} from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";
import {
  IconChevronDown,
  IconFileUpload,
  IconPdf,
  IconUpload,
  IconX,
} from "@tabler/icons-react";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import {
  generatePdf,
  processAnnotations,
} from "../../../utils/partitionerUtils";
import { useMediaQuery } from "@mantine/hooks";
import { samplePdf } from "../../../samplePdf";
import { Document, Thumbnail, pdfjs } from "react-pdf";
import { OptionsState } from "../../../types";
import InfoTip from "../../InfoTip";
import {
  convertToPDF,
  decryptPdf,
  partitionDocument,
} from "../../../apiService";
import ReactGA from "react-ga4";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.mjs`;

const UploadZone = ({
  setFile,
  loading,
  setLoading,
  openErrorDialog,
  options,
  setOptions,
  tempFile,
  setTempFile,
  setJsonData,
  setBoxes,
  setCallId,
  pageCount,
  setPageCount,
  apiKey,
  setOriginalFile,
  setConvertedFile,
}: {
  setFile: Dispatch<SetStateAction<File | null>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  openErrorDialog: (message: string) => void;
  options: OptionsState;
  setOptions: Dispatch<SetStateAction<OptionsState>>;
  tempFile: File | null;
  setTempFile: Dispatch<SetStateAction<File | null>>;
  setJsonData: Dispatch<SetStateAction<any>>;
  setBoxes: Dispatch<SetStateAction<any>>;
  setCallId: Dispatch<SetStateAction<string>>;
  pageCount: number | null;
  setPageCount: Dispatch<SetStateAction<number | null>>;
  apiKey: string;
  setOriginalFile: Dispatch<SetStateAction<File | null>>;
  setConvertedFile: Dispatch<SetStateAction<File | null>>;
}) => {
  const theme = useMantineTheme();
  const mobileScreen = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const openRef = useRef<() => void>(null);
  const [decryptLoading, setDecryptLoading] = useState<boolean>(false);
  const [fileToChunk, setFileToChunk] = useState<File | null>(null);

  const handleUpload = async (fileToUpload: File | null) => {
    if (!fileToUpload || !apiKey || !pageCount || !tempFile) return;
    try {
      const response = await partitionDocument(fileToUpload, apiKey, options);
      const arynCallId = response.headers["x-aryn-call-id"];
      console.log("Request Id: ", arynCallId);
      if (response.data.error) {
        console.log(response.data.error);
        openErrorDialog("Please try again after sometime.");
        setFile(null);
      } else {
        if (arynCallId) {
          setCallId(arynCallId);
        }
        setJsonData(response.data.elements);
        setBoxes(processAnnotations(response.data));
        const boxes = processAnnotations(response.data);
        let newBlob;
        try {
          let existingPdfBytes;
          if (fileToUpload.type === "application/pdf") {
            existingPdfBytes = await fileToUpload.arrayBuffer();
          } else {
            existingPdfBytes = await tempFile.arrayBuffer();
          }
          newBlob = await generatePdf(existingPdfBytes, boxes, pageCount);
        } catch (error: any) {
          if (error.message.includes("encrypted")) {
            console.log("Encrypted PDF detected!");
            try {
              const decryptedBlob = await decryptPdf(fileToUpload, apiKey);
              newBlob = await generatePdf(decryptedBlob, boxes);
            } catch (error) {
              console.error("An error occurred while decrypting:", error);
              openErrorDialog("Something wrong happened while decrypting");
            }
          } else {
            console.error("An error occurred while drawing boxes:", error);
            openErrorDialog("Something wrong happened while drawing boxes");
          }
        }
        if (newBlob) {
          setFile(
            new File([newBlob], fileToUpload.name, {
              type: "application/pdf",
            })
          );
          setOriginalFile(fileToUpload);
        }
      }
    } catch (error: any) {
      console.log(error);
      const errorMessage = error.isAxiosError && error.response?.data?.detail
        ? "Unable to process request: " + error.response.data.detail
        : "Something wrong happened while partitioning " + error.message;
      console.error(errorMessage);
      openErrorDialog(errorMessage);
      setFile(null);
    } finally {
      setLoading(false);
      setTempFile(null);
    }
  };

  const handleOnDrop = async (files: File[]) => {
    setDecryptLoading(true);
    if (!apiKey) return;
    console.log(files[0].name);
    const fileType = files[0].type;
    if (fileType === "application/pdf") {
      setTempFile(files[0]);
    } else {
      console.log("This is NOT a PDF file");
      const pdfBlob = await convertToPDF(files[0], apiKey);
      const pdfFile = new File([pdfBlob], files[0].name, {
        type: "application/pdf",
      });
      setTempFile(pdfFile);
      setConvertedFile(pdfFile);
    }
    setFileToChunk(files[0]);
  };

  const handleClickChunk = async () => {
    ReactGA.event({
      category: "DocParse",
      action: "[DocParse]chunk document",
    });
    setLoading(true);
    handleUpload(fileToChunk);
  };

  const handleSamplePdf = async () => {
    try {
      const base64String = samplePdf;

      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      const file = new File([blob], "magic.pdf", { type: "application/pdf" });
      setDecryptLoading(true);
      setTempFile(file);
    } catch (error) {
      console.error("Error fetching file:", error);
      openErrorDialog("Error uploading sample pdf");
      setLoading(false);
    }
  };

  const handlePDFLoadSuccess = ({ numPages }: { numPages: number }) => {
    setOptions({
      ...options,
      selected_pages: [[1, Math.min(numPages, 25)]],
    });
    setPageCount(Math.min(numPages, 25));
    setDecryptLoading(false);
  };

  return (
    <Stack gap={0} align="center" pos="relative">
      {!(tempFile || loading) ? (
        <>
          <Dropzone
            w={mobileScreen ? "" : "80vw"}
            h="18.75rem"
            onDrop={handleOnDrop}
            openRef={openRef}
            onReject={(files) => {
              console.log("rejected files", files);
              openErrorDialog("Upload rejected");
            }}
            multiple={false}
            // loading={loading}
            styles={(theme) => ({
              root: mobileScreen
                ? {}
                : {
                    border: "1px solid lightgray",
                    cursor: "pointer",
                    background: theme.colors.gray[1],
                    borderRadius: 10,
                    padding: 10,
                  },
            })}
            hidden={mobileScreen}
            dragEventsBubbling={true}
          >
            {!mobileScreen && (
              <div style={{ pointerEvents: "none" }}>
                <Stack
                  align="center"
                  justify="center"
                  h="calc(18.75rem - 20px)"
                  style={{
                    pointerEvents: "none",
                    border: "1px dashed lightgray",
                    borderRadius: 10,
                    height: "",
                  }}
                >
                  <Dropzone.Accept>
                    <IconUpload
                      style={{
                        width: rem(52),
                        height: rem(52),
                        color: "var(--mantine-color-blue-6)",
                      }}
                      stroke={1.5}
                    />
                  </Dropzone.Accept>
                  <Dropzone.Reject>
                    <IconX
                      style={{
                        width: rem(52),
                        height: rem(52),
                        color: "var(--mantine-color-red-6)",
                      }}
                      stroke={1.5}
                    />
                  </Dropzone.Reject>
                  <Dropzone.Idle>
                    <Image src="/file-upload-2.png" w={90} />
                  </Dropzone.Idle>

                  <div>
                    <Text size="md" c={theme.colors.gray[7]} inline>
                      Select or drop your file here
                    </Text>
                  </div>
                </Stack>
              </div>
            )}
          </Dropzone>
          <LoadingOverlay
            zIndex={10}
            visible={decryptLoading}
            loaderProps={{ type: "bars" }}
          />
          {mobileScreen && (
            <Group wrap="nowrap" gap={0}>
              <Button
                style={{
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  pointerEvents: "all",
                }}
                leftSection={<IconFileUpload />}
                onClick={() => openRef.current?.()}
                size="lg"
              >
                Choose File
              </Button>
              <Menu
                transitionProps={{ transition: "pop" }}
                position="bottom-end"
                withinPortal
                styles={{
                  item: {
                    width: "13.5rem",
                    height: "3rem",
                    fontSize: "1rem",
                  },
                }}
              >
                <Menu.Target>
                  <ActionIcon
                    variant="filled"
                    color={theme.primaryColor}
                    size={50}
                    style={{
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      border: 0,
                      borderLeft: "1px solid white",
                    }}
                  >
                    <IconChevronDown
                      style={{ width: rem(16), height: rem(16) }}
                      stroke={1.5}
                    />
                  </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    leftSection={
                      <IconPdf
                        style={{ width: rem(16), height: rem(16) }}
                        stroke={1.5}
                        color={theme.colors.blue[5]}
                      />
                    }
                    onClick={handleSamplePdf}
                  >
                    Try Sample File
                  </Menu.Item>

                  <Menu.Item
                    leftSection={
                      <IconUpload
                        style={{ width: rem(16), height: rem(16) }}
                        stroke={1.5}
                        color={theme.colors.blue[5]}
                      />
                    }
                    onClick={() => openRef.current?.()}
                  >
                    Upload File
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Group>
          )}
        </>
      ) : (
        <Stack gap="2rem" align="center">
          <Box
            w={mobileScreen ? "" : "80vw"}
            h={mobileScreen ? "15rem" : "28rem"}
            style={(theme) => ({
              border: "1px solid lightgray",
              background: theme.colors.gray[1],
              borderRadius: 10,
              padding: 10,
              position: "relative",
            })}
          >
            <LoadingOverlay
              visible={loading}
              zIndex={10}
              overlayProps={{ radius: 10, blur: 2 }}
              loaderProps={{ type: "bars" }}
            />

            <Flex
              align="top"
              justify="center"
              h={mobileScreen ? "calc(15rem - 20px)" : "calc(28rem - 20px)"}
              style={{
                border: "1px dashed lightgray",
                borderRadius: 10,
                overflow: "scroll",
              }}
            >
              <ActionIcon
                style={{
                  position: "absolute",
                  top: "15px",
                  right: "15px",
                  cursor: "pointer",
                  zIndex: 5,
                }}
                size="lg"
                color="gray"
                onClick={() => setTempFile(null)}
              >
                <IconX />
              </ActionIcon>
              <Box
                style={{
                  border: "1px solid lightgray",
                  position: "relative",
                }}
              >
                <Document file={tempFile} onLoadSuccess={handlePDFLoadSuccess}>
                  <Thumbnail
                    pageNumber={1}
                    width={1000}
                    scale={mobileScreen ? 0.35 : 1}
                  />
                </Document>
              </Box>
            </Flex>
          </Box>
          <Flex gap="2rem" direction={mobileScreen ? "column" : "row"}>
            <Group>
              <Text size="sm">
                Auto-threshold
                <InfoTip message="This option dynamically adjusts the threshold for bounding box predictions. When disabled, threshold for bounding boxes can be set manually." />
              </Text>
              <Switch
                size="sm"
                disabled={loading}
                checked={options.auto_threshold}
                onChange={(event) => {
                  setOptions({
                    ...options,
                    auto_threshold: event.target.checked,
                  });
                }}
              />
            </Group>
            <Group wrap="nowrap" justify="space-between">
              <Text size="sm">
                Threshold
                <InfoTip message="This sets the threshold for accepting the model's predicted bounding boxes. A lower value results in more bounding boxes, but they may overlap. A higher value reduces the number of overlaps, but may miss legitimate bounding boxes." />
              </Text>
              <NumberInput
                size={mobileScreen ? "sm" : "xs"}
                placeholder="Threshold"
                min={0}
                max={1}
                step={0.02}
                maw={mobileScreen ? "5rem" : "4rem"}
                value={options.threshold}
                onChange={(value) =>
                  setOptions({ ...options, threshold: Number(value) })
                }
                disabled={loading || options.auto_threshold}
              />
            </Group>
            <Group wrap="nowrap" justify="space-between">
              <Text size="sm">
                Enable OCR
                <InfoTip message="This option enables optical character recognition. If false, it will attempt to extract the text from the underlying text layer of the PDF file." />
              </Text>
              <Switch
                size="sm"
                checked={options.use_ocr}
                disabled={loading}
                onChange={(event) =>
                  setOptions({
                    ...options,
                    use_ocr: event.target.checked,
                    ocr_images: event.target.checked && options.ocr_images,
                    ocr_tables: event.target.checked && options.ocr_tables,
                  })
                }
              />
            </Group>
            <Group wrap="nowrap" justify="space-between">
              <Text size="sm">
                Extract Table Structure
                <InfoTip message="This option runs a separate model that extract cells from regions of the document identified as tables. These are stored with the Table element in the JSON output." />
              </Text>
              <Switch
                size="sm"
                disabled={loading}
                checked={options.extract_table_structure}
                onChange={(event) =>
                  setOptions({
                    ...options,
                    extract_table_structure: event.target.checked,
                  })
                }
              />
            </Group>
            <Group wrap="nowrap" justify="space-between">
              <Text size="sm">
                Extract Images
                <InfoTip message="When enabled, each region identified as an image is cropped and attached it to the associated ImageElement (base64 encoded). It can later be used for further processing (e.g. image summarization)." />
              </Text>
              <Switch
                size="sm"
                disabled={loading}
                checked={options.extract_images}
                onChange={(event) =>
                  setOptions({
                    ...options,
                    extract_images: event.target.checked,
                  })
                }
              />
            </Group>
          </Flex>
          <Button onClick={handleClickChunk} size="md" disabled={loading}>
            Parse Document
          </Button>
        </Stack>
      )}
    </Stack>
  );
};
export default UploadZone;
